<template>
    <div class="jxb-cooperate-container">
        <div class="jxb-cooperate">

            <!-- <div class="head">
                <div class="jxb-logo"></div>
                <div class="nav">

                    <div class="tip">

                    </div>

                    <span class="nav-btn">驾校登录</span>
                </div>
            </div> -->

            <div class="cooperate-container">
                <h2>欢迎入驻</h2>

                <h4 class="cooperate-text">请确保信息真实有效，不得冒用他人身份或驾校资质，一经发现将承担所有法律责任，并永久撤销该注册人平台使用资格。</h4>

                <div class="cooperate-form">
                    <div class="form">

                        <div class="form-group">
                            <label>驾校定位</label>
                            <div>
                                <qq-map @position-changed="positionChanged"/>
                            </div>
                        </div>
                        <div class="err-tip"></div>

                        <div class="form-group">
                            <label>城市<i>*</i></label>
                            <div>
                                <city-picker v-model="city" />
                            </div>
                        </div>

                        <div class="err-tip"></div>

                        <!-- <div class="form-group radio-form">
                            <label>类型<i>*</i></label>
                            <div class="user-type-conatiner">
                                <div class="user-type active"><i class="avatar"></i><b>总校/直营</b>
                                    <p>入驻需认证，用于营销、招生、管理</p>
                                </div>
                                <div class="user-type "><i class="avatar2"></i><b>分校/挂靠</b>
                                    <p>入驻需对应总校同意</p>
                                </div>
                                <div class="user-type "><i class="avatar3"></i><b>代理</b>
                                    <p>入驻流程简单，主要用于招生</p>
                                </div>
                            </div>
                        </div> -->

                        <div class="form-group ">
                            <label>驾校全称<i>*</i></label>
                            <input v-model="name" type="text" placeholder="请填写与营业执照上相同的公司名称" maxlength="30">
                        </div>
                        <div class="err-tip"></div>

                        <div class="form-group">
                            <label>姓名<i>*</i></label>
                            <input v-model="contact" type="text" placeholder="请输入管理员姓名" maxlength="20">
                        </div>
                        <div class="err-tip"></div>

                        <div class="form-group">
                            <label>手机号码<i>*</i></label>
                            <input v-model="tel" type="text" placeholder="此手机号用于驾校后台登录" maxlength="11">
                        </div>
                        <div class="err-tip"></div>

                        <div class="form-group">
                            <label>验证码<i>*</i></label>
                            <input v-model="smsCode" class="sms-code" type="text" placeholder="请输入验证码" maxlength="6">
                            <button class="form-btn" :disabled="disabled" @click="sendSmsCode">{{text}}</button>
                        </div>
                        <div class="err-tip"></div>

                        <div class="form-group" style="height: 100px;">
                            <label>管理员身份证<i>*</i></label>
                            <upload v-model="admin_image" />
                        </div>

                        <div class="form-group" style="height: 100px;">
                            <label>营业执照<i>*</i></label>
                            <upload v-model="lisence_image" />
                        </div>

                        <div class="submit">
                            <span class="form-btn" @click="submit">提交</span>
                        </div>

                        <div class="protocol">
                            <div>点击提交即视为同意<a>《用户使用协议》</a>和<a>《隐私政策》</a></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import VerifyCode from '@/mixins/verify-code';
import CityPicker from '@/components/widgets/city-picker.vue';
import {registerSchool, getCityInfo} from '@/api/training';
import {getCodeApi, registerVerify} from '@/api/user';
import Upload from '@/components/widgets/upload.vue';
import qqMap from '@/components/widgets/t-map.vue';

export default defineComponent({
    components: {
        CityPicker,
        Upload,
        qqMap
    },

    mixins: [VerifyCode],

    data: function () {
        return {
            lat: 0,
            lng: 0,
            name: '',
            contact: '',
            tel: '',
            smsCode: '',
            city: [],
            admin_image: [],
            lisence_image: [],
            keyCode: '',
            codeVal: '', // 图形验证码
        };
    },

    computed: {
        ...mapGetters('app', ['getCityIds'])
    },

    mounted: function () {
        this.getCode();
    },

    methods: {

        positionChanged: function (coord) {

            this.lat = coord.lat;
            this.lng = coord.lng;

            getCityInfo(coord).then((res) => {

                let ids = this.getCityIds(res.data.cityinfo.city_id);

                this.city = ids;
            }).catch((e) => {
                console.error(e)
            });
        },

        getCode() {
            getCodeApi()
                .then(res => {
                    this.keyCode = res.data.key;
                })
                .catch(res => {
                    this.$toast(res);
                });
        },

        sendSmsCode: function () {

            if (!this.tel.trim() || !/^1\d{10}$/.test(this.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            registerVerify({
                phone: this.tel,
                type: 'verify',
                key: this.keyCode,
                code: this.codeVal
            })
            .then(res => {
                this.$toast(res.msg);
                this.smsSendInterval();
            })
            .catch(res => {
                this.$toast(res);
                // if (res.data.status === 402) {
                //  this.codeUrl = `${VUE_APP_API_URL}/sms_captcha?key=${this.keyCode}`;
                //  this.isShowCode = true;
                // }
            });
        },

        submit: function () {
            let data = JSON.parse(JSON.stringify(this.$data));

            data.admin_image = this.admin_image[0];
            data.lisence_image = this.lisence_image[0];

            if (!data.city[2]) {
                this.$toast('请选择城市');
                return;
            }

            if (!data.name.trim()) {
                this.$toast('请填写驾校名');
                return;
            }

            if (!data.contact.trim()) {
                this.$toast('姓名不能为空');
                return;
            }

            if (!data.tel.trim() || !/^1\d{10}$/.test(data.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            if (!data.smsCode.trim()) {
                this.$toast('请输入验证码');
                return;
            }

            if (!data.admin_image) {
                this.$toast('请上传管理员身份证');
                return;
            }

            if (!data.lisence_image) {
                this.$toast('请上传营业执照');
                return;
            }

            registerSchool(data).then((res) => {

                this.$toast(res.msg);
            }, (err) => {
                this.$toast(err);
            });
        }
    }
});

</script>

<style scoped>
html {
    height: 100%;
    margin: 0;
    padding: 0
}

body {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 14px;
    height: 100%;
    font-family: "Microsoft YaHei", Arial, Helvetica, sans-serif;
    line-height: 1;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent
}

* {
    box-sizing: border-box
}

:after,
:before {
    box-sizing: border-box
}

script,
title {
    display: none
}

li {
    display: list-item
}

table {
    display: table
}

tbody {
    display: table-row-group
}

thead {
    display: table-header-group
}

tfoot {
    display: table-footer-group
}

colgroup {
    display: table-column-group
}

col {
    display: table-column
}

tr {
    display: table-row
}

td,
th {
    display: table-cell
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0
}

li,
ol,
ul {
    list-style: none;
    padding: 0;
    margin: 0
}

a {
    cursor: pointer;
    text-decoration: none
}

i {
    font-style: normal
}

img {
    border: 0
}

.clearfix:after {
    clear: both;
    display: block;
    content: ''
}

input,
textarea {
    resize: none;
    outline: 0;
    margin: 0;
    border-radius: 0
}

button {
    border: none;
    background: 0 0;
    border-radius: 0;
    margin: 0;
    color: inherit;
    line-height: inherit;
    outline: 0 !important;
    cursor: pointer;
    -webkit-appearance: none
}

a,
b,
button,
dd,
div,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
h6,
i,
img,
input,
li,
p,
select,
span,
strong,
textarea,
ul {
    padding: 0;
    margin: 0;
    border: 0;
    font-style: normal;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit
}

li,
ol,
ul {
    list-style: none
}

.clearfix:after,
.clearfix:before {
    display: table;
    content: " "
}

.clearfix:after {
    clear: both
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto
}

.cl:after,
.cl:before {
    display: table;
    content: " ";
    clear: both
}

.fl {
    float: left
}

.fr {
    float: right
}

.clear {
    clear: both
}

.cl-ul:after,
.cl-ul:before {
    display: table;
    content: " ";
    clear: both
}

.cl-ul>li {
    float: left
}

.el {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.el2 {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2
}

.al {
    text-align: left
}

.ac {
    text-align: center
}

.ar {
    text-align: right
}

.hidden,
.hide {
    display: none
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}

.btn-action,
.btn-primary {
    padding: 0 15px;
    line-height: 30px;
    background: #fff;
    border-radius: 2px;
    border: solid 1px #5288ff;
    font-size: 14px;
    color: #5288ff;
    text-align: center
}

.com-sidebar::-webkit-scrollbar,
.modal::-webkit-scrollbar,
.noscroll::-webkit-scrollbar {
    width: 0;
    height: 0
}

.nobar::-webkit-scrollbar {
    width: 0;
    height: 0
}

.btn-primary {
    background: #5288ff;
    border-color: #5288ff;
    color: #fff
}

switch {
    display: inline-block;
    width: 50px;
    height: 25px;
    border-radius: 12.5px;
    position: relative;
    cursor: pointer
}

switch:before {
    display: inline-block;
    content: '';
    width: 21px;
    height: 21px;
    position: absolute;
    top: 2px;
    background: #fff;
    border-radius: 50%;
    -webkit-transition: all .3s;
    transition: all .3s
}

switch:after {
    display: inline-block;
    content: '';
    width: 18px;
    line-height: 25px;
    position: absolute;
    top: 0;
    color: #fff;
    font-size: 13px
}

switch.on {
    background: #5288ff
}

switch.on:before {
    left: 27px
}

switch.on:after {
    left: 8px;
    content: '开'
}

switch.off {
    background: #bfbfbf
}

switch.off:before {
    left: 2px
}

switch.off:after {
    left: 28px;
    content: '关'
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none
}

input[type=number] {
    -moz-appearance: textfield
}

.jxb-cooperate-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative
}

.jxb-cooperate-container .jxb-cooperate {
    width: 100%;
    height: 100%;
    background: url(../../assets/files/d107f94d8b0582d78f78dd669ffcddf3.png) no-repeat center/top 1600px 446px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.jxb-cooperate-container .jxb-cooperate .head {
    padding: 15px 40px 15px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 8px 28px 0 rgba(171, 193, 227, .1)
}

.jxb-cooperate-container .jxb-cooperate .head .jxb-logo {
    width: 232px;
    height: 40px;
    background: url(https://web-resource.mucang.cn/web/qiye-jkbd-v3/logo3.svg);
    background-size: 100% 100%
}

.jxb-cooperate-container .jxb-cooperate .head .nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center
}

.jxb-cooperate-container .jxb-cooperate .head .nav .tip {
    font-size: 14px;
    line-height: 20px;
    color: #1f263e;
    margin-right: 20px
}

.jxb-cooperate-container .jxb-cooperate .head .nav .tip>span {
    margin-left: 16px
}

.jxb-cooperate-container .jxb-cooperate .head .nav>span {
    width: 86px;
    height: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    cursor: pointer;
    color: #6a7dff;
    font-size: 14px;
    border: 1px solid #6a7dff;
    border-radius: 4px;
    margin-right: 10px
}

.jxb-cooperate-container .jxb-cooperate .head .nav>span:hover {
    color: #4c68ef
}

.jxb-cooperate-container .jxb-cooperate .head .nav .nav-btn {
    margin-right: 0;
    font-size: 14px;
    color: #fff;
    background-color: #6a7dff
}

.jxb-cooperate-container .jxb-cooperate .head .nav .nav-btn:hover {
    background: #6b81fe;
    border-color: #6b81fe;
    color: #fff
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container {
    flex: 1;
    background-color: #f3f5f8;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-orient: vertical;
    align-items: center
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container input {
    outline: 0;
    border: none
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container input::-webkit-input-placeholder {
    color: #a0a0a0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container input:-moz-placeholder {
    color: #a0a0a0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container input::moz-placeholder {
    color: #a0a0a0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container input:-ms-input-placeholder {
    color: #a0a0a0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container>h2 {
    margin: 31px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: #232841;
    font-size: 28px;
    line-height: 38px;
    font-weight: 700
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form {
    width: 1000px;
    background-color: #fff;
    box-shadow: 0 4px 30px 0 rgba(99, 121, 204, .1);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .err-tip {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 30px;
    color: #ff4a40;
    font-size: 12px;
    line-height: 16px;
    padding-left: 77px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .user-type {
    padding: 4px 0 10px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    padding-left: 77px;
    color: #999
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form {
    margin-top: 30px;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-btn {
    background-color: #5288ff;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 14px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    min-height: 36px;
    margin-bottom: 0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form {
    height: auto;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-bottom: 30px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form>span {
    margin-left: 34px;
    color: #ff4a40
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    width: 420px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 130px;
    height: 196px;
    padding: 0 15px;
    border: 1px solid #e6e6e6
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type.active {
    border: 1px solid #5288ff;
    background: #f8fbff
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type>i {
    margin-top: 15px;
    display: block;
    width: 68px;
    height: 65px;
    background-size: 100% 100%;
    background-image: url(../../assets/files/2ef8e4c087cf6b466d8d1fa432eea422.png)
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type>i.avatar2 {
    background-image: url(../../assets/files/5713385797d8ae151096946c68733f9f.png)
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type>i.avatar3 {
    background-image: url(../../assets/files/5131b806c2a131a6df80b9e166fab689.png)
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type>b {
    margin-top: 11px;
    font-size: 14px;
    line-height: 20px;
    color: #333
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group.radio-form .user-type-conatiner .user-type>p {
    font-size: 12px;
    line-height: 17px;
    margin-top: 7px;
    color: #999;
    width: 100%
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group>label {
    height: 36px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 77px;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 0;
    font-weight: 400;
    color: #333
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group>label.wx {
    margin-left: 44px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group>label i {
    font-size: 14px;
    margin-top: -5px;
    color: #ff4a40
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .radio-wrap {
    width: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-right: 30px;
    color: #333;
    font-size: 14px;
    margin-left: 0;
    font-weight: 400
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .radio-wrap.last-child {
    margin-right: 0
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .radio-wrap .radio {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    margin-right: 9px;
    line-height: 19px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group input {
    width: 420px;
    height: 36px;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    text-indent: 15px;
    color: #333;
    font-size: 14px;
    line-height: 19px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group input:focus {
    border-color: #5288ff
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group input.sms-code {
    width: 280px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group input.qq {
    width: 150px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group input.error {
    border-color: #ff4a40
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .no-school {
    margin-left: 20px;
    font-size: 14px;
    color: #5288ff;
    line-height: 19px;
    cursor: pointer
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .no-school:hover {
    text-underline: #5288ff
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .captcha-img {
    width: 260px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group>span {
    margin-left: 20px;
    font-size: 14px;
    color: #999;
    line-height: 19px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2 {
    position: relative;
    width: 420px;
    height: 36px;
    color: #333
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2>input,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select>input {
    width: 100%;
    cursor: pointer;
    background: url(../../assets/files/f6ff168f00d91ccf3e848050fcef23ac.png) no-repeat 99% center
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2>input:hover,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select>input:hover {
    background-image: url(../../assets/files/edfe08f623323ab23de0ec3d85b0fd92.png)
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select.error .mybtn,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2.error .mybtn {
    border-color: #ff4a40
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select .btn,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2 .btn {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #e6e6e6
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2>img,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select>img {
    position: absolute;
    right: 10px;
    top: 9px;
    width: 18px;
    height: 18px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select .city-com,
.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2 .city-com {
    position: absolute;
    left: 0;
    top: 38px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2 {
    width: 245px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2.agent {
    width: 420px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .select2 .form-control {
    box-shadow: none
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .form-btn {
    margin-left: 15px;
    width: 120px;
    height: 36px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .form-group .form-btn.hover {
    background-color: #e0e2e6;
    cursor: not-allowed
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .submit {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-top: 10px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .submit .form-btn {
    width: 200px;
    height: 42px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol {
    margin-top: 16px;
    margin-bottom: 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    color: #333;
    font-size: 12px;
    line-height: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol .protocol-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid #eee;
    cursor: pointer;
    margin-right: 7px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol .protocol-checkbox.checked {
    border: none;
    background-image: url(../../assets/files/763f07d5d16be6f0d1c42a392a32be11.png);
    background-size: 100% 100%
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol a {
    color: #5288ff;
    cursor: pointer
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol a:hover {
    text-underline: #5288ff
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .cooperate-form .form .protocol a:focus {
    text-decoration: none
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .copyright {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #333;
    font-size: 10px;
    margin: 15px 0;
    line-height: 14px
}

.jxb-cooperate-container .jxb-cooperate .cooperate-container .copyright .beian {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../../assets/files/d0289dc0a46fc5b15b3363ffa78cf6c7.png) no-repeat center center;
    background-size: 12px 12px;
    vertical-align: middle
}

.jxb-cooperate-container .jxb-cooperate .cooperate-text {
    width: 798px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff4d4f;
    line-height: 20px;
    margin-top: -24px;
    margin-bottom: 28px
}

.jxb-cooperate-container .jxb-cooperate .my-model .modal-dialog .modal-content .modal-footer button {
    vertical-align: middle;
    min-width: 160px
}

.jxb-cooperate-container .jxb-cooperate .mybtn.mybtn-primary {
    color: #fff;
    background-color: #5288ff
}

.jxb-cooperate-container .jxb-cooperate .mybtn.size1 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px
}

.jxb-cooperate-container .jxb-cooperate .mybtn {
    min-height: 30px;
    border-radius: 2px;
    padding: 0 12px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer
}

.jxb-cooperate-container .jxb-cooperate .modal-dialog {
    position: relative !important;
    top: 30% !important;
    left: 0 !important
}

.jxb-cooperate-container .jxb-cooperate .mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 10;
    overflow: hidden
}

.jxb-cooperate-container .jxb-cooperate .mark #captcha {
    width: 444px;
    height: 306px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -222px;
    margin-top: -150px
}

.jxb-cooperate-container .jxb-cooperate .new-radio {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none
}

.jxb-cooperate-container .jxb-cooperate .new-radio:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin-right: 6px;
    vertical-align: -3px
}

.jxb-cooperate-container .jxb-cooperate .new-radio.active:before {
    border: 5px solid #4d94fe
}

.my-select {
    position: relative
}

.my-select .my-sel-chk-name {
    white-space: nowrap;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}

.my-select .my-sel-mul-name {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 26px 4px 10px;
    background-color: #f5f5f5;
    margin-right: 4px;
    color: #333;
    position: relative;
    border-radius: 2px
}

.my-select .my-sel-mul-name i {
    background: url(../../assets/files/7c30c96e055d0bc65699e69bcd5f06a1.svg) no-repeat center center;
    width: 16px;
    height: 16px;
    background-size: 12px 12px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    top: 3px
}

.my-select .my-sel-mul-name i:hover {
    background-color: #e2e2e2
}

.my-select .mybtn {
    min-height: 36px;
    height: auto;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    line-height: 34px;
    width: 100%;
    padding: 0 30px 0 15px;
    text-align: left
}

.my-select .mybtn:focus {
    border-color: #5288ff
}

.my-select .mybtn:focus .mycaret {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.my-select.placeholder .caret,
.my-select.placeholder .my-sel-chk-name,
.my-select.placeholder .my-show-text {
    color: #999
}

.my-select .disabled {
    cursor: text;
    color: #999;
    cursor: not-allowed
}

.my-select .disabled:focus {
    border-color: #e6e6e6
}

.my-select .disabled:focus .mycaret {
    -webkit-transform: rotate(0);
    transform: rotate(0)
}

.my-select .disabled:hover .mycaret {
    display: block !important
}

.my-select .disabled .mycaret {
    background-image: url(../../assets/files/3d3c820412f0e1c930eddec1f445b41d.svg) !important
}

.my-select.placeholder:hover .mycaret {
    background-image: url(../../assets/files/3d3c820412f0e1c930eddec1f445b41d.svg)
}

.my-select.has-data:hover .multiple .mycaret {
    display: block
}

.my-select.has-data:hover .mycaret {
    display: none
}

.my-select.has-data:hover .my-sel-remove {
    display: block
}

.my-select .dw-search-nodata a {
    color: #999;
    cursor: text
}

.my-select .dw-search-nodata a:hover {
    background-color: #fff !important;
    color: #999
}

.my-select .dw-search-input {
    padding: 0
}

.my-select .dw-search-input .form-control {
    width: 100%;
    height: 42px;
    border: none;
    background: url(../../assets/files/e01836c4004a664cf022a77f403f59e6.png) no-repeat 8px center;
    text-indent: 20px;
    border: none !important
}

.my-select .dw-search-input .form-control:focus {
    border-color: transparent !important
}

.my-select>.mybtn {
    min-width: 150px;
    text-align: left;
    padding-right: 20px
}

.my-select>.mybtn.hover {
    border-color: #5288ff
}

.my-select .mycaret {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 18px;
    height: 18px;
    background: url(../../assets/files/3d3c820412f0e1c930eddec1f445b41d.svg) no-repeat
}

.my-select .my-sel-remove {
    position: absolute;
    right: 2px;
    font-size: 10px;
    color: #999;
    width: 20px;
    height: 20px;
    top: 8px;
    text-align: center;
    display: none;
    line-height: 20px
}

.my-select .more {
    font-size: .12rem;
    color: #999;
    background-color: #eee;
    margin-left: 5px;
    border-radius: 4px;
    padding: 0 6px
}

.my-select .dropdown-menu-g {
    display: none;
    box-shadow: none;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
    padding: 0;
    position: absolute;
    background-color: #fff;
    width: 100%;
    z-index: 2
}

.my-select .dropdown-menu-g .dropdown-menu2 {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    border-left: none;
    box-shadow: none;
    margin-top: 0;
    border-radius: 2px;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 0;
    z-index: 10
}

.my-select .dropdown-menu-g .dropdown-menu2 li a {
    height: 40px;
    line-height: 40px;
    display: block;
    padding: 0 14px 0 14px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.my-select .dropdown-menu-g .dropdown-menu2 li a:hover {
    background: #eff6fe;
    text-decoration: none
}

.my-select .dropdown-menu-g .dropdown-menu2 li a.my-active {
    color: #409eff
}

.dialog-confirm {
    position: relative;
    z-index: 1002
}

.dialog-confirm .toast {
    position: fixed;
    z-index: 1061;
    left: 50%;
    top: 50%;
    background-color: rgba(0, 0, 0, .9);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 36px;
    padding: 0 20px;
    font-size: 20px;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    opacity: 0;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s, -webkit-transform .3s
}

.dialog-confirm .toast.show {
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
    opacity: 1
}

.my-model {
    overflow: auto
}

.my-model.modal {
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000
}

.my-model .modal-dialog {
    width: 700px
}

.my-model .modal-dialog .modal-content {
    padding: 0 10px;
    box-shadow: none;
    border-radius: 4px;
    border: none;
    position: relative
}

.my-model .modal-dialog .modal-content .modal-header {
    padding: 20px 0;
    margin: 0 20px
}

.my-model .modal-dialog .modal-content .modal-header.new-header {
    border-bottom: none;
    margin-bottom: -20px
}

.my-model .modal-dialog .modal-content .modal-header .close {
    background: url(../../assets/files/435a39f45a1dde27cc8c823739fda353.svg) no-repeat center center;
    width: 24px;
    height: 24px;
    opacity: 1;
    position: absolute;
    right: 8px;
    border-radius: 12px;
    top: 10px
}

.my-model .modal-dialog .modal-content .modal-header .close:hover {
    background-color: #f4f4f4
}

.my-model .modal-dialog .modal-content .dialog-confir-text {
    font-size: 20px;
    padding: 35px 35px 35px;
    line-height: 1.6;
    word-break: break-all;
    text-align: center
}

.my-model .modal-dialog .modal-content .modal-title {
    font-size: 16px;
    font-weight: 700
}

.my-model .modal-dialog .modal-content .modal-body {
    padding: 15px 20px
}

.my-model .modal-dialog .modal-content .modal-footer {
    text-align: center
}

.my-model .modal-dialog .modal-content .modal-footer .mybtn {
    margin-right: 20px
}

.my-model .modal-dialog .modal-content .modal-footer.new-footer {
    padding: 0 0 50px 0;
    border-top: none;
    text-align: center
}

.my-model .modal-dialog .modal-content .modal-footer button:last-child {
    margin-right: 0
}

.my-model .modal-dialog .modal-content .modal-footer button {
    vertical-align: middle;
    min-width: 160px
}

.my-model .modal-dialog .modal-content .modal-footer button a {
    text-decoration: none;
    color: inherit;
    display: block
}

.my-model .modal-dialog .modal-content .modal-footer .btn {
    border-radius: 2px
}

.my-model .topText {
    color: #ff4a40;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
    font-weight: 400
}

.my-footer {
    text-align: center;
    padding: 30px 0
}

.my-footer .ghs-img {
    background: url(../../assets/files/d0289dc0a46fc5b15b3363ffa78cf6c7.png) left center no-repeat;
    background-size: 20px 20px;
    padding-left: 25px
}

.my-footer .ghs-phone {
    margin-top: 10px;
    margin-left: 21px
}
</style>