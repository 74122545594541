
import {defineComponent} from 'vue';
import {mapGetters} from 'vuex';

import VerifyCode from '@/mixins/verify-code';
import CityPicker from '@/components/widgets/city-picker.vue';
import {registerSchool, getCityInfo} from '@/api/training';
import {getCodeApi, registerVerify} from '@/api/user';
import Upload from '@/components/widgets/upload.vue';
import qqMap from '@/components/widgets/t-map.vue';

export default defineComponent({
    components: {
        CityPicker,
        Upload,
        qqMap
    },

    mixins: [VerifyCode],

    data: function () {
        return {
            lat: 0,
            lng: 0,
            name: '',
            contact: '',
            tel: '',
            smsCode: '',
            city: [],
            admin_image: [],
            lisence_image: [],
            keyCode: '',
            codeVal: '', // 图形验证码
        };
    },

    computed: {
        ...mapGetters('app', ['getCityIds'])
    },

    mounted: function () {
        this.getCode();
    },

    methods: {

        positionChanged: function (coord) {

            this.lat = coord.lat;
            this.lng = coord.lng;

            getCityInfo(coord).then((res) => {

                let ids = this.getCityIds(res.data.cityinfo.city_id);

                this.city = ids;
            }).catch((e) => {
                console.error(e)
            });
        },

        getCode() {
            getCodeApi()
                .then(res => {
                    this.keyCode = res.data.key;
                })
                .catch(res => {
                    this.$toast(res);
                });
        },

        sendSmsCode: function () {

            if (!this.tel.trim() || !/^1\d{10}$/.test(this.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            registerVerify({
                phone: this.tel,
                type: 'verify',
                key: this.keyCode,
                code: this.codeVal
            })
            .then(res => {
                this.$toast(res.msg);
                this.smsSendInterval();
            })
            .catch(res => {
                this.$toast(res);
                // if (res.data.status === 402) {
                //  this.codeUrl = `${VUE_APP_API_URL}/sms_captcha?key=${this.keyCode}`;
                //  this.isShowCode = true;
                // }
            });
        },

        submit: function () {
            let data = JSON.parse(JSON.stringify(this.$data));

            data.admin_image = this.admin_image[0];
            data.lisence_image = this.lisence_image[0];

            if (!data.city[2]) {
                this.$toast('请选择城市');
                return;
            }

            if (!data.name.trim()) {
                this.$toast('请填写驾校名');
                return;
            }

            if (!data.contact.trim()) {
                this.$toast('姓名不能为空');
                return;
            }

            if (!data.tel.trim() || !/^1\d{10}$/.test(data.tel.trim())) {
                this.$toast('请填写正确的手机号');
                return;
            }

            if (!data.smsCode.trim()) {
                this.$toast('请输入验证码');
                return;
            }

            if (!data.admin_image) {
                this.$toast('请上传管理员身份证');
                return;
            }

            if (!data.lisence_image) {
                this.$toast('请上传营业执照');
                return;
            }

            registerSchool(data).then((res) => {

                this.$toast(res.msg);
            }, (err) => {
                this.$toast(err);
            });
        }
    }
});

