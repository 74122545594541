<template>
    <div ref="map" :style="{width: width + 'px', height: height + 'px'}"></div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {jsonp} from '@/utils/request';

export default defineComponent({

    props: {
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 300
        },
        markCenter: Boolean,
        lat: Number,
        lng: Number,
        disabled: Boolean
    },

    data: function () {
        return {
            map: null
        };
    },

    mounted: function () {

        let mapKey = this.$store.state.training.config.tengxun_map_key;

        this.$TMap(mapKey, (TMap) => {

            let center;

            if (this.lat && this.lng) {
                center = new TMap.LatLng(this.lat, this.lng);
            }

            let mapOptions: any = {
                zoom: 10
            };

            if (center) {
                mapOptions.center = center;
            }

            const map = this.map = new TMap.Map(this.$refs.map, mapOptions);

            const markerLayer = new TMap.MultiMarker({
                geometries: [{id: 'marker', position: map.getCenter()}],
                map: map
            });

            // const geocoder = new TMap.Geocoder();

            // geocoder.setComplete((result) => {
            //     console.log(result);
            // });

            if (this.disabled) {
                return;
            }

            map.on('click', (e) => {

                markerLayer.updateGeometries([{id: 'marker', position: e.latLng}]);

                this.$emit('position-changed', e.latLng);
                // geocoder.getAddress(e.latLng);
            });

            if (!center) {

                // webserviceApi ip 获取地址
                // https://apis.map.qq.com/ws/location/v1/ip?output=jsonp&key=FPXBZ-EXFYX-LEK4C-ZVH25-H6MDZ-2IBGH&callback=__callback1622118108044

                jsonp(`https://apis.map.qq.com/ws/location/v1/ip?output=jsonp&key=${mapKey}`, (res) => {

                    if (res.result) {
                        let latLng = new TMap.LatLng(res.result.location.lat, res.result.location.lng);
                        markerLayer.updateGeometries([{id: 'marker', position: latLng}]);
                        map.setCenter(latLng);

                        this.$emit('position-changed', latLng);
                    }
                });
            }
        });
    },

    beforeUnmount: function () {

        if (this.map) {
            this.map.destroy();
            this.map = null;
        }
    },

    methods: {

    }
});
</script>