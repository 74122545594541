<template>
    <div>
        <select v-model="province_id" :disabled="disabled" placeholder="请选择省份" @change="cityChange(0)">
            <option v-for="(p) in cityData" :value="p.v" :key="p.v">
                {{p.n}}
            </option>
        </select>

        <select v-if="province" v-model="city_id" :disabled="disabled" placeholder="请选择城市" @change="cityChange(1)">
            <option v-for="(c) in province.c" :value="c.v" :key="c.v">
                {{c.n}}
            </option>
        </select>

        <select v-if="city" v-model="area_id" :disabled="disabled" placeholder="请选择区域" @change="cityChange(2)">
            <option v-for="(a) in city.c" :value="a.v" :key="a.v">
                {{a.n}}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState} from 'vuex';

import {getCity} from '@/api/api';

export default defineComponent({

    props: {
        disabled: Boolean,

        modelValue: {
            type: Array
        }
    },

    data () {
        return {
            loading: false,
            province_id: 0,
            city_id: 0,
            area_id: 0,
            cityList: []
        }
    },

    mounted() {
        this.valueChange(this.modelValue);
        // this.getCityList();
    },

    computed: {

        ...mapState('app', ['cityData']),

        province: function () {

            return this.cityData.find((item) => {

                return item.v === this.province_id;
            });
        },

        city: function () {

            return this.province ? this.province.c.find((item) => {
                return item.v === this.city_id;
            }) : null;
        },

        area: function () {

            return this.city ? this.city.c.find((item) => {
                return item.v === this.area_id;
            }) : null;
        }
    },

    watch: {
        modelValue: function (v) {
            this.valueChange(v);
        }
    },

    methods: {

        valueChange: function (v) {
            this.province_id = v[0] || 0;
            this.city_id = v[1] || 0;
            this.area_id = v[2] || 0;
        },

        getCityList: function () {
            this.loading = true
            getCity().then(res => {
                this.loading = false
                this.cityList = res.data
            })
        },

        cityChange: function (index) {

            if (index < 1) {
                this.city_id = 0;
            }

            if (index < 2) {
                this.area_id = 0;
            }

            let value = [this.province_id || 0, this.city_id || 0, this.area_id || 0];

            this.$emit('update:modelValue', value);
        }
    }
});
</script>

<style scoped lang="scss">
select {
    border-radius: 2px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    background: none;
    border-color: #eee;
    padding: 8px 10px;
}
</style>
